import React, { useState } from 'react';
import * as dayjs from 'dayjs';
import { useStaticQuery, graphql } from 'gatsby';

import { Heading, EmptyState } from '../elements';
import EventTabs from './EventTabs';
import CurrentEventCard from './CurrentEventCard';
import PastEventCard from './PastEventCard';

const Events = () => {
  const { allSanityEvent: eventData } = useStaticQuery(graphql`
    {
      allSanityEvent {
        edges {
          node {
            _id
            _createdAt
            type
            title
            subtitle
            slug {
              current
            }
            startAt
            description
            image {
              asset {
                gatsbyImageData
              }
            }
            otherImages {
              _key
              asset {
                url
                gatsbyImageData(width: 1500, height: 700, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);

  const data = eventData.edges;

  const [eventsType, setEventsType] = useState('currentEvents');
  if (!data) {
    return null;
  }
  const currentEvents = data.filter((o) => {
    if (dayjs(o.node.startAt).unix() > dayjs(new Date()).unix()) {
      return o;
    }
    return null;
  });

  const pastEvents = data.filter((o) => {
    if (dayjs(o.node.startAt).unix() < dayjs(new Date()).unix()) {
      return o;
    }
    return null;
  });

  return (
    <section className="section is-medium">
      <div className="container">
        <EventTabs eventsType={eventsType} setEventsType={setEventsType} />
        <Heading dark className="has-text-centered mb-6">
          {eventsType === 'currentEvents' ? 'Current Events' : 'Past Events'}
        </Heading>
        {eventsType === 'currentEvents' && (
          <>
            {currentEvents.length === 0 && (
              <EmptyState text="We currently have no events at this time. Please keep checking back to find out more about our events." />
            )}
            <div className="columns is-multiline is-variable is-6">
              {currentEvents.map(({ node: item }) => {
                if (!item) {
                  return null;
                }
                return <CurrentEventCard key={item._id} item={item} />;
              })}
            </div>
          </>
        )}
        {eventsType === 'pastEvents' &&
          pastEvents.map(({ node: item }) => {
            if (!item) {
              return null;
            }
            return <PastEventCard key={item._id} item={item} />;
          })}
      </div>
    </section>
  );
};

export default Events;
