import React from 'react';
import styled from 'styled-components';

import swal from 'sweetalert';
import { GraphQLClient, gql } from 'graphql-request';

import config from '../../utils/config';
import ContactForm from './ContactForm';
import { Heading, Markdown } from '../elements';

const Wrapper = styled.div`
  @media screen and (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const MarkdownStyled = styled.div`
  h1 {
    color: ${(props) => props.theme.lightShades} !important;
  }
`;

const LeftColumn = styled.div`
  background-color: ${(props) => props.theme.mainBrandColor};
  background-image: url('/images/contact-bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
`;

const client = new GraphQLClient(config.apiUrl, {
  timeout: 60000,
});

const contactUsMutation = gql`
  mutation contact($input: ContactInput) {
    contact(input: $input) {
      status
      message
    }
  }
`;

const handleSubmit = async (formData) => {
  const variables = { input: formData };
  await client
    .request(contactUsMutation, variables)
    .then((data2) => {
      console.log(data2, 'message');
      swal('Success!', 'Submit Successfully!', 'success');
    })
    .catch((error) => {
      if (error) {
        swal('Something went wrong!', error, 'error');
      }
    });
};

const ContactUs = ({ title, description, showLeftSection }) => {
  return (
    <Wrapper className={!showLeftSection && 'py-6 my-5 section'}>
      <div className={!showLeftSection && 'container'}>
        {!showLeftSection && (
          <Heading dark centered className="mb-5 pb-3">
            {title}
          </Heading>
        )}
        <div
          className={
            showLeftSection ? 'columns is-gapless' : 'columns is-centered'
          }
        >
          {showLeftSection && (
            <LeftColumn className="column hero is-medium">
              {description && (
                <MarkdownStyled className="hero-body content has-text-white">
                  <Markdown
                    className="has-text-white"
                    description={description}
                  />
                </MarkdownStyled>
              )}
            </LeftColumn>
          )}
          <div
            className={
              !showLeftSection
                ? 'column has-background-white is-8'
                : 'column has-background-white hero is-medium'
            }
          >
            <div className={showLeftSection && 'hero-body'}>
              <ContactForm onSubmit={(val) => handleSubmit(val)} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ContactUs;
