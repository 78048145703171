import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';

import SanityImage from '../global/SanityImage';
import { Heading, Markdown } from '../elements';

const ContentWrapper = styled.div`
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : '')};
  p {
    color: ${(props) => props.theme.darkAccent} !important;
    line-height: 30px;
  }
  .button {
    background: transparent !important;
  }
`;

const IconWrapper = styled.span`
  border: 2px solid red;
  padding: 1.3rem;
  border-radius: 2rem;
`;

const Section = styled.div`
  @media screen and (min-width: 1024px) {
    padding: 7.2rem 4.5rem !important;
  }
  background: ${(props) => props.theme.lightShades};
`;

const LatestCamp = () => {
  const { sanityCamp: data } = useStaticQuery(graphql`
    {
      sanityCamp {
        _id
        type
        title
        slug {
          current
        }
        description
        image {
          asset {
            gatsbyImageData(width: 1200)
          }
        }
      }
    }
  `);
  return (
    <Section className="section">
      <div className="container">
        <ContentWrapper className="columns is-vcentered is-variable is-8">
          <div className="column">
            <h2 className="mb-5 has-text-weight-medium has-text-black">
              {data.type}
            </h2>
            <Heading size="3xl" dark className="mb-5">
              {data.title}
            </Heading>
            <div className="content has-text-black">
              <Markdown description={data.description} />
            </div>

            {data.slug && data.slug.current && (
              <Link
                to="/camps"
                className="button has-text-weight-semibold pl-0 is-borderless"
              >
                <span className="is-size-4">Learn More</span>
                <IconWrapper className="icon is-small ml-3 has-text-danger">
                  <i className="fas fa-arrow-right" />
                </IconWrapper>
              </Link>
            )}
          </div>
          <div className="column">
            {data.image && data.image.asset && (
              <SanityImage
                fluid={data.image.asset.gatsbyImageData}
                alt={data.title || 'bizzy buddies'}
              />
            )}
          </div>
        </ContentWrapper>
      </div>
    </Section>
  );
};
export default LatestCamp;
