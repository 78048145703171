import React from 'react';
import styled from 'styled-components';
import { Content, Heading, Subtitle } from '../elements';

const Section = styled.div`
  background-image: url('/images/social-curved.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  @media screen and (min-width: 1024px) {
    padding: 7.2rem 4.5rem !important;
  }
`;

const WhoWeAre = ({ title, description, whoWeAreItem = [] }) => {
  return (
    <div className="has-background-danger-dark">
      <Section className="section is-medium ">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-7">
              <Content title={title} description={description} />
            </div>
          </div>

          <div className="columns is-multiline pt-5">
            {whoWeAreItem.map((item) => {
              if (!item) {
                return null;
              }
              return (
                <div
                  className="column is-3 p-2 has-text-centered"
                  key={item._key}
                >
                  <Heading className="mb-3" size="3xl">
                    {item.title}
                  </Heading>
                  <Subtitle>{item.description}</Subtitle>
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    </div>
  );
};
export default WhoWeAre;
