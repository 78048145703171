import React from 'react';
import { Link } from 'gatsby';
import * as dayjs from 'dayjs';

import SanityImage from '../global/SanityImage';
import { Heading, Subtitle } from '../elements';

const CurrentEventCard = ({ item }) => {
  return (
    <Link to={`/event/${item.slug.current}`} className="column is-4">
      {item.image && item.image.asset && (
        <SanityImage
          fluid={item.image.asset.gatsbyImageData}
          alt={item.title || 'bizzy buddies'}
        />
      )}
      <div className="card-content px-0">
        <h2 className="mb-5 has-text-weight-medium has-text-grey-dark">
          EVENT
        </h2>
        <Heading
          size="2xl"
          dark
          className="mb-2 has-text-grey-dark has-text-weight-semibold"
        >
          {item.title}
        </Heading>
        <Subtitle className="mb-4" isBlack>
          {item.subtitle}
        </Subtitle>

        <time dateTime={dayjs(item.startAt).format('DD MMMM YYYY')}>
          {dayjs(item.startAt).format('DD MMMM YYYY')}
        </time>
      </div>
    </Link>
  );
};

export default CurrentEventCard;
