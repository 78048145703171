import React from 'react';
import styled from 'styled-components';

import SanityImage from '../global/SanityImage';
import { Heading, Markdown } from '../elements';

const Section = styled.section`
  background: ${(props) => props.theme.lightCreamAccent} !important;
`;

const Figure = styled.figure`
  img {
    border-radius: 9999px !important;
    height: 150px !important;
    width: 150px !important;
  }
`;

const ScheduleBlock = (props) => {
  const { title, scheduleItem = [] } = props;
  return (
    <Section className="section">
      <div className="container">
        <Heading className="has-text-centered my-6" dark>
          {title}
        </Heading>
        <div className="columns is-multiline">
          {scheduleItem &&
            scheduleItem.map((el) => {
              if (!el) return null;
              return (
                <div className="column is-3" key={el._key}>
                  <div className="has-text-centered">
                    <Heading dark size="2xl" className="mb-5">
                      {el.title}
                    </Heading>
                    {el.image && el.image.asset && (
                      <Figure className="m-auto">
                        <SanityImage
                          className="is-rounded"
                          gatsbyImageId={el.image.asset.id}
                          alt={el.title || 'Bizzy buddies'}
                        />
                      </Figure>
                    )}
                    <div className="my-4">
                      <Markdown description={el.description} />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Section>
  );
};
export default ScheduleBlock;
