import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { Content, OfferCard } from '../elements';

const Section = styled.section`
  @media screen and (min-width: 1024px) {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important;
  }
`;

const Offer = ({ title }) => {
  const { allSanityOffer } = useStaticQuery(graphql`
    {
      allSanityOffer {
        edges {
          node {
            _id
            title
            slug {
              current
            }
            subtitle
            description
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const data = allSanityOffer.edges;

  if (!data) return null;

  return (
    <Section className="section is-medium">
      {title && (
        <div className="columns is-centered mb-5">
          <div className="column is-7">
            <Content dark title={title} />
          </div>
        </div>
      )}
      {data.map(({ node: item }, i) => {
        return (
          <OfferCard
            data={item}
            key={item._id}
            color="true"
            reverse={i % 2 === 0}
          />
        );
      })}
    </Section>
  );
};

export default Offer;
