import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import SanityImage from '../global/SanityImage';

import { Heading, Subtitle, Button } from '../elements';

const Wrapper = styled.div`
  button {
    color: black;
    border: 2px solid black !important;
  }
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : '')};
`;

const AboutBlock = ({
  title,
  image,
  description,
  subtitle,
  buttonText,
  slug,
  isReverse,
}) => {
  return (
    <section className="section">
      <div classnames="container">
        <Wrapper className="columns is-vcentered mt-5" reverse={isReverse}>
          <div className="column">
            {subtitle && (
              <Subtitle isBlack className="mb-4">
                {subtitle}
              </Subtitle>
            )}
            <Heading dark className="mb-4">
              {title}
            </Heading>

            <ReactMarkdown
              source={description}
              className="has-text-black text-base has-text-weight-medium leading-9 mt-4"
            />
            {slug && slug.current && (
              <a href={slug.current} rel="noreferrer">
                <Button secondary className="mt-5 is-dark">
                  {buttonText || 'Learn More'}
                </Button>
              </a>
            )}
          </div>
          {image && image.asset && (
            <div className="column">
              <SanityImage
                width={1000}
                gatsbyImageId={image.asset.id}
                alt={title || 'bizzy buddies'}
              />
            </div>
          )}
        </Wrapper>
      </div>
    </section>
  );
};

export default AboutBlock;
