import React from 'react';
import styled from 'styled-components';

import Hero from './global/Hero';
import WhoWeAre from './whoWeAre/WhoWeAre';
import LatestCamp from './camps/LatestCamp';
import ReviewBlock from './review/ReviewBlock';
import Contact from './forms/Contact';
import AboutBlock from './about/AboutBlock';
import InfoBlock from './global/Information';
import FaqBlock from './camps/FaqBlock';
import ContactInfo from './global/ContactInfo';
import HomeGrid from './home/HomeGrid';
import Package from './birthday/Package';
import Events from './event/Events';
import TeamBlock from './team/TeamBlock';
import Offer from './offer/Offer';
import DiscountBlock from './sessions/BookingInformation';
import Itinerary from './camps/Itinerary';
import ScheduleBlock from './camps/ScheduleBlock';
import VideoBlock from './video/VideoBlock';

// sanity blocks
const Blocks = {
  hero: Hero,
  whoWeAre: WhoWeAre,
  latestCamp: LatestCamp,
  reviewBlock: ReviewBlock,
  contactBlock: Contact,
  aboutBlock: AboutBlock,
  informationBlock: InfoBlock,
  faq: FaqBlock,
  contactInfo: ContactInfo,
  sliderBlock: HomeGrid,
  packageBlock: Package,
  eventBlock: Events,
  teamBlock: TeamBlock,
  offerBlock: Offer,
  discountBlock: DiscountBlock,
  itineraryBlock: Itinerary,
  scheduleBlock: ScheduleBlock,
  videoBlock: VideoBlock,
};

const ContentBlocks = (type) => {
  const BlockNotFound = () => (
    <div className="section">
      <div className="container">
        <PreWrapper>
          Error: The requested component
          <em className="has-text-black content">```{type}```</em> could not be
          found.
        </PreWrapper>
      </div>
    </div>
  );

  if (typeof Blocks[type] === 'undefined') {
    return BlockNotFound;
  }
  return Blocks[type];
};

export default ContentBlocks;

const PreWrapper = styled.pre`
  padding: 5.6rem 0;
  text-align: center;
  border: 1px solid var(--coral);
  color: var(--black);
  white-space: normal;
  line-height: 1.4;
`;
