import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Heading, Subtitle, Content } from '../elements';
import SanityImage from '../global/SanityImage';

const Section = styled.div`
  .box {
    border-radius: 0;
  }
`;

const Package = (props) => {
  const { title, image, description, price, points = [], linkTo } = props;
  return (
    <Section className="section">
      <div className="container">
        <div className="box p-6 has-background-danger-dark">
          <div className="columns is-vcentered">
            <div className="column is-8">
              <Content left title={title} />
              {price && <Heading>£ {price}</Heading>}
              <div className="mt-4">
                {points.map((item) => (
                  <div className="mb-5" key={item}>
                    <div className="media">
                      <div className="media-left">
                        <svg
                          className=""
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="16"
                            cy="16"
                            r="15.25"
                            stroke="white"
                            strokeWidth="1.5"
                          />
                          <path
                            d="M10.6665 16.6667L13.9998 20L21.9998 12"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="media-content">
                        <Subtitle className="icon-text">
                          <span>{item}</span>
                        </Subtitle>
                      </div>
                    </div>
                  </div>
                ))}
                {linkTo && (
                  <Link
                    className="button is-primary has-text-weight-bold"
                    to={linkTo}
                  >
                    Enquire
                  </Link>
                )}
              </div>
            </div>
            {image && (
              <div className="column is-4">
                <SanityImage
                  width={1200}
                  gatsbyImageId={image.asset.id}
                  alt={title || 'Bizzy buddies Birthday party'}
                />
              </div>
            )}
          </div>
          <Subtitle className="has-text-centered">{description}</Subtitle>
        </div>
      </div>
    </Section>
  );
};
export default Package;
