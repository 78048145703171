import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import SanityImage from '../global/SanityImage';
import { Content, SliderArrow } from '../elements';

const Wrapper = styled.div`
  @media screen and (min-width: 1024px) {
    padding: 7.2rem 4.5rem !important;
  }
  .box {
    justify-content: center;
  }

  img {
    width: 100% !important;
    object-fit: contain !important;
  }
  .column.is-1 {
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

const HomeGrid = (props) => {
  const { title, subtitle, images = [] } = props;
  const [number, setNumber] = useState(0);
  const sliderRef = useRef();
  const goNext = () => {
    sliderRef.current.slickNext();
  };

  const goPrev = () => {
    sliderRef.current.slickPrev();
  };
  const beforeChange = (prev, next) => {
    setNumber(Math.floor(next));
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    beforeChange,

    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <Wrapper className="section is-medium">
      <div className="container">
        <Content dark title={title} description={subtitle} />
        <div className="columns is-mobile is-vcentered is-justify-content-center">
          <div className="column is-1 is-paddingless ">
            <SliderArrow disabled={number === 0} onClick={goPrev}>
              <i className="fas fa-angle-left" />
            </SliderArrow>
          </div>
          <div className="column is-10">
            <Slider {...settings} ref={sliderRef}>
              {images &&
                images.map((item) => {
                  if (!item) {
                    return null;
                  }
                  return (
                    <div key={item._key} className="p-2">
                      {item.asset && (
                        <SanityImage
                          width={1500}
                          gatsbyImageId={item.asset.id}
                          alt={item.alt || 'Camps'}
                        />
                      )}
                    </div>
                  );
                })}
            </Slider>
          </div>
          <div className="column is-1  is-paddingless ">
            <SliderArrow onClick={goNext} type="button">
              <i className="fas fa-angle-right" />
            </SliderArrow>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default HomeGrid;
