import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Heading, Markdown, Button } from '../elements';

const Wrapper = styled.div`
  background-image: url('/images/contact-curves.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const ButtonWrapper = styled.div`
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const ContactInfo = ({ title, description }) => {
  return (
    <div className="has-background-danger-dark">
      <Wrapper>
        <section className="section is-medium has-text-centered">
          <div className="container">
            <div className="columns is-centered is-vcentered">
              <div className="column is-8">
                <Heading className="mb-4">{title}</Heading>
                <Markdown
                  className="is-size-4 has-text-white"
                  description={description}
                />
                <ButtonWrapper className="mt-5">
                  <Link to="/contact">
                    <Button className="is-medium">Contact Us</Button>
                  </Link>
                </ButtonWrapper>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
    </div>
  );
};
export default ContactInfo;
