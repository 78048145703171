import React from 'react';
import Slider from 'react-slick';
import * as dayjs from 'dayjs';
import styled from 'styled-components';
import { Heading } from '../elements';
import SanityImage from '../global/SanityImage';

const Container = styled.div`
  .slick-dots {
    bottom: -40px;
    li {
      width: 5%;
    }
  }
`;

const PastEventCard = ({ item }) => {
  const settings = {
    customPaging(i) {
      return (
        <span className="mt-2">
          <SanityImage
            fluid={item.otherImages[i].asset.gatsbyImageData}
            alt={item.title || 'bizzy buddies'}
          />
        </span>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Container className="mb-6 pb-6">
      <div className="mb-6">
        <Heading size="2xl" dark className="mb-4">
          {item.title}-{' '}
          <time
            className="is-size-4"
            dateTime={dayjs(item.startAt).format('DD MMMM YYYY')}
          >
            {dayjs(item.startAt).format('DD MMMM YYYY')}
          </time>
        </Heading>
        <Slider {...settings}>
          {item.otherImages.map((image) => (
            <div key={image._key}>
              <SanityImage
                fluid={image.asset.gatsbyImageData}
                alt={item.title || 'bizzy buddies'}
              />
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  );
};
export default PastEventCard;
