import React from 'react';
import ReactPlayer from 'react-player';

import { Heading } from '../elements';

const VideoBlock = ({ url, title }) => {
  if (!url) return null;
  return (
    <section className="section">
      <div className="container">
        {title && (
          <Heading className="my-5 has-text-black has-text-centered">
            {title}
          </Heading>
        )}

        <figure className="image is-16by9">
          <ReactPlayer
            class="has-ratio"
            width="640"
            height="360"
            playing
            loop
            controls={false}
            url={url}
            className="has-ratio"
          />
        </figure>
      </div>
    </section>
  );
};

export default VideoBlock;
