import React from 'react';
import { Link } from 'gatsby';

import { Heading, Markdown, Button } from '../elements';

const BookingInformation = (props) => {
  const { title, description } = props;
  return (
    <div className="section is-medium">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column">
            <Heading dark>{title}</Heading>
          </div>
          <div className="column content">
            <Markdown description={description} />
            <Link to="/contact">
              <Button className="button is-dark mt-4">Contact Us</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingInformation;
