import React, { useState } from 'react';
import styled from 'styled-components';
import { saveAs } from 'file-saver';

import { Markdown, Heading, Button } from '../elements';

const Wrapper = styled.section`
  .flayer {
    text-decoration: underline;
  }
`;

const Itinerary = ({ title, description, buttonText, buttonLink, flayer }) => {
  const [isLoading, setIsLoading] = useState(false);
  const downloadImage = () => {
    saveAs(flayer.asset.url, 'bizzy-buddies.png');
  };
  return (
    <Wrapper className="section">
      <div className="container">
        <div className="columns is-centered has-text-centered">
          <div className="column is-8">
            <Heading dark className="mb-4">
              {title}
            </Heading>
            <div className="content">
              <Markdown description={description} />
            </div>
            <a href={buttonLink || '/contact'} target="_blank" rel="noreferrer">
              <Button secondary className="mt-5 is-dark">
                {buttonText || 'Contact Us'}
              </Button>
            </a>
            {flayer && flayer.asset && (
              <div className="mt-6">
                <button
                  className={`button is-clickable flayer is-paddingless is-borderless ${
                    isLoading ? 'is-dark is-loading' : ''
                  }`}
                  type="button"
                  onClick={() => {
                    setIsLoading(true);
                    downloadImage();
                    setTimeout(() => {
                      setIsLoading(false);
                    }, 1000);
                  }}
                >
                  Download our flyer
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Itinerary;
