import React, { useState } from 'react';
import styled from 'styled-components';
import { Heading } from '../elements';

const ArticleWrapper = styled.article`
  background-color: rgba(253, 239, 224, 0.5);
  .message-header {
    background-color: rgba(253, 239, 224, 0.5);
  }
  p {
    line-height: 32px;
  }
`;

const FaqItem = ({ data }) => {
  const [active, setActive] = useState(false);
  return (
    <ArticleWrapper className="message is-cream">
      <div
        className="message-header is-clickable"
        onClick={() => setActive(!active)}
      >
        <Heading className="has-text-black" size="base">
          {data.question}
        </Heading>
        {active && (
          <span className="icon has-text-black">
            <i className="fas fa-chevron-down" />
          </span>
        )}
        {!active && (
          <span className="icon has-text-black">
            <i className="fas fa-chevron-up" />
          </span>
        )}
      </div>

      {active && (
        <div className="message-body">
          <p className="text-base has-text-grey-dark">{data.description}</p>
        </div>
      )}
    </ArticleWrapper>
  );
};
export default FaqItem;
