import React from 'react';
import * as yup from 'yup';
import { withFormik } from 'formik';

import { InputGroup, TextArea, Button } from '../elements';

const ContactForm = (props) => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <InputGroup
              name="firstName"
              placeholder="First name*"
              type="text"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                errors.firstName && touched.firstName
                  ? errors.firstName
                  : undefined
              }
            />
          </div>
          <div className="field">
            <InputGroup
              name="lastName"
              placeholder="Last name*"
              type="text"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                errors.lastName && touched.lastName
                  ? errors.lastName
                  : undefined
              }
            />
          </div>
        </div>
      </div>
      <InputGroup
        placeholder="Email*"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.email && touched.email ? errors.email : undefined}
      />

      <TextArea
        placeholder="Your message*"
        name="message"
        type="text"
        value={values.message}
        onChange={handleChange}
        onBlur={handleBlur}
        errors={errors.message && touched.message ? errors.message : undefined}
      />
      <Button
        type="submit"
        disabled={isSubmitting}
        secondary
        roundedNone
        className="button is-dark is-fullwidth mt-5"
      >
        Send Message
      </Button>
    </form>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    email: '',
    firstName: '',
    lastName: '',
    message: '',
  }),
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required!'),
    firstName: yup.string().required('First name is required!'),
    lastName: yup.string().required('Last name is required!'),
    message: yup.string(),
  }),

  handleSubmit: (values, { setSubmitting, props, resetForm }) => {
    props.onSubmit(values).finally(() => {
      setSubmitting(false);
      resetForm();
    });
  },
  displayName: 'ContactForm', // helps with React DevTools
})(ContactForm);
