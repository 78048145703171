import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  :focus {
    box-shadow: none !important;
    outline: none;
    border-color: transparent;
  }
`;

const EventTabs = ({ eventsType, setEventsType }) => {
  return (
    <div className="buttons is-justify-content-center	mb-6 pb-4">
      <Button
        type="button"
        onClick={() => setEventsType('currentEvents')}
        className={`button is-rounded ${
          eventsType === 'currentEvents'
            ? 'has-background-danger-dark has-text-white'
            : 'is-white'
        }`}
      >
        Current Events
      </Button>
      <Button
        type="button"
        onClick={() => setEventsType('pastEvents')}
        className={`button is-rounded ${
          eventsType === 'pastEvents'
            ? 'has-background-danger-dark has-text-white'
            : 'is-white'
        }`}
      >
        Past Events
      </Button>
    </div>
  );
};

export default EventTabs;
