import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/global/Seo';
import ContentBlocks from '../components/ContentBlocks';

const Pages = ({ data }) => {
  const { page } = data;
  if (!page) return null;
  return (
    <Layout>
      <Seo
        title={page.title}
        description={page.description}
        keywords={page.title}
        image={page.image.asset.url}
      />
      {page.contentBlocks &&
        page.contentBlocks.map((block, idx) =>
          React.createElement(ContentBlocks(block._type), {
            idx,
            key: block._key,
            ...block,
          }),
        )}
    </Layout>
  );
};

Pages.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Pages;

export const query = graphql`
  query ($slug: String!) {
    page: sanityPages(slug: { current: { eq: $slug } }) {
      title
      description
      heading
      slug {
        current
      }
      image {
        asset {
          gatsbyImageData
          _id
          url
        }
      }
      contentBlocks: _rawContentBlocks(resolveReferences: { maxDepth: 10 })
    }
  }
`;
