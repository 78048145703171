import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import { Heading, Markdown, SliderArrow } from '../elements';

const Section = styled.div`
  @media screen and (min-width: 1024px) {
    padding: 7.2rem 4.5rem !important;
  }
  background: ${(props) => props.theme.lightCreamAccent} !important;
  .column.is-1 {
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

const NameStyled = styled.h2`
  color: ${(props) => props.theme.mainBrandColor};
`;

const ReviewBlock = ({ title, reviews }) => {
  const [number, setNumber] = useState(0);
  const sliderRef = useRef();
  const goNext = () => {
    sliderRef.current.slickNext();
  };

  const goPrev = () => {
    sliderRef.current.slickPrev();
  };
  const beforeChange = (prev, next) => {
    setNumber(Math.floor(next));
  };
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange,

    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  if (reviews.length === 0) return null;

  return (
    <Section className="section is-medium">
      <div className="container">
        {title && (
          <Heading centered className="has-text-black mb-5">
            {title}
          </Heading>
        )}

        <div className="columns is-mobile is-vcentered is-gapless">
          <div className="column is-1 is-hidden-mobile">
            <SliderArrow disabled={number === 0} onClick={goPrev}>
              <i className="fas fa-angle-left" />
            </SliderArrow>
          </div>

          <div className="column is-12-mobile is-10-desktop">
            <Slider {...settings} ref={sliderRef}>
              {reviews &&
                reviews.map((node) => (
                  <div key={node._id}>
                    <div className="columns is-centered">
                      <div className="column is-8 has-text-centered">
                        <Markdown
                          className="has-text-black"
                          description={node.description}
                        />
                        <NameStyled className="has-text-weight-medium my-4">
                          {node.name}
                        </NameStyled>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
          <div className="column is-1  is-hidden-mobile">
            <SliderArrow onClick={goNext} type="button">
              <i className="fas fa-angle-right" />
            </SliderArrow>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ReviewBlock;
