import React from 'react';
import styled from 'styled-components';
import { Content } from '../elements';

const Section = styled.section`
  background-image: url('/images/curved.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const InformationBlock = (props) => {
  const { title, description } = props;
  return (
    <div className="has-background-danger-dark" id="information">
      <Section className="section is-medium">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-9">
              <Content title={title} description={description} />
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default InformationBlock;
