import React from 'react';

import FaqItem from './FaqItem';
import Content from '../elements/Content';

const FaqBlock = ({ faqOption = [], title, description }) => {
  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="mb-6">
              <Content dark title={title} description={description} />
            </div>

            {faqOption &&
              faqOption.map((item) => <FaqItem key={item._key} data={item} />)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqBlock;
