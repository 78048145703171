import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { Content, Markdown, Heading } from '../elements';

import SanityImage from '../global/SanityImage';

const ContentWrapper = styled.div`
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : '')};
  p {
    color: ${(props) => props.theme.textGreyDark} !important;
    line-height: 30px;
  }
  .box {
    background: ${(props) => props.theme.lightCreamAccent};
    border-radius: 0;
  }
  .button {
    background: transparent;
  }
`;

const TeamBlock = (props) => {
  const { title, description } = props;

  const { allSanityTeam: teamData } = useStaticQuery(graphql`
    {
      allSanityTeam {
        edges {
          node {
            _id
            type
            title
            description
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const data = teamData.edges;

  return (
    <div className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-7 mb-6">
            <Content dark title={title} description={description} />
          </div>
        </div>

        {data.map(({ node: item }, i) => {
          if (!item) {
            return null;
          }
          return (
            <ContentWrapper
              key={item._id}
              className="columns is-gapless mb-6"
              reverse={i % 2 === 0}
            >
              <div className="column hero">
                <div className="box hero-body">
                  {item.type && (
                    <h2 className="mb-5 has-text-weight-medium has-text-grey-dark">
                      {item.type}
                    </h2>
                  )}
                  <Heading size="3xl" dark className="mb-3">
                    {item.title}
                  </Heading>
                  <div className="content">
                    <Markdown description={item.description} />
                  </div>
                </div>
              </div>
              <div className="column">
                {item.image.asset && (
                  <SanityImage
                    className="hero"
                    fluid={item.image.asset.gatsbyImageData}
                    alt={item.title || 'Bizzy buddies team member'}
                  />
                )}
              </div>
            </ContentWrapper>
          );
        })}
      </div>
    </div>
  );
};

export default TeamBlock;
